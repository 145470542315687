// if (window.matchMedia('(max-width: 768px)').matches) {
//     let type_img_scroll = document.querySelector(".type_img");
//     type_img_scroll.scrollLeft = 230;
// }




window.addEventListener('load', function () {
  var elements = document.getElementsByClassName('type_img');

  // 取得した要素に対して処理を行う
  for (var i = 0; i < elements.length; i++) {
    if (window.matchMedia('(max-width: 768px)').matches) {
        let type_img_scroll = document.querySelector(".type_img");
        type_img_scroll.scrollLeft = 230;
    }
  }
});
