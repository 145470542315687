var pageId = '';

pageId = document.querySelector('body').getAttribute('id');
switch (pageId) {
    //body id='top'
    case 'top':
        require('./top.ts');
        break;
    case 'commn_space':
        require('./commn_space.ts');
        break;
    case 'concept':
        require('./concept.ts');
        break;
    case 'plan':
        require('./plan.ts');
        break;
    //body id='map'
    case 'outline':
        window.addEventListener('DOMContentLoaded', function () {
            const todayElm = document.querySelectorAll('.js-todayElm');
            const nextElm = document.querySelectorAll('.js-nextElm');
            const upToday = new Date();
            const upNext = new Date();
            upNext.setDate(upToday.getDate() + 14)

            const todayM = upToday.getMonth() + 1;
            const nextM = upNext.getMonth() + 1;
            for (let i = 0; i < todayElm.length; i++) {
                todayElm[i].innerHTML = upToday.getFullYear() + "年" + todayM + "月" + upToday.getDate() + "日";
            }
            for (let i = 0; i < nextElm.length; i++) {
                nextElm[i].innerHTML = upNext.getFullYear() + "年" + nextM + "月" + upNext.getDate() + "日";
            }
        });
        break;
}
;
//common
require('./anime.ts');

const nav = document.querySelectorAll('header nav li');
for (let i = 0; i < nav.length; i++) {
    if (location.href.match('/concept.php')) {
        document.querySelector('header nav li:nth-of-type(2)').classList.add('active');
    } else if (location.href.match('/design.php')) {
        document.querySelector('header nav li:nth-of-type(3)').classList.add('active');
    } else if (location.href.match('/access.php')) {
        document.querySelector('header nav li:nth-of-type(4)').classList.add('active');
    } else if (location.href.match('/location.php')) {
        document.querySelector('header nav li:nth-of-type(5)').classList.add('active');
    } else if (location.href.match('/common_space.php')) {
        document.querySelector('header nav li:nth-of-type(6)').classList.add('active');
    // } else if (location.href.match('/design.php')) {
    //     document.querySelector('header nav li:nth-of-type(7)').classList.add('active');
    } else if (location.href.match('/plan.php')) {
        document.querySelector('header nav li:nth-of-type(7)').classList.add('active');
    } else if (location.href.match('/type_A.php') ||
               location.href.match('/type_C2.php') ||
               location.href.match('/type_F.php') ||
               location.href.match('/type_H.php') ||
               location.href.match('/type_Q1.php') ||
               location.href.match('/type_P.php')) {
        document.querySelector('header nav li:nth-of-type(7)').classList.add('active');
    } else if (location.href.match('/quality.php')) {
        document.querySelector('header nav li:nth-of-type(8)').classList.add('active');
    } else if (location.href.match('/modelroom.php')) {
        document.querySelector('header nav li:nth-of-type(9)').classList.add('active');
    } else if (location.href.match('/company.php')) {
        document.querySelector('header nav li:nth-of-type(10)').classList.add('active');
    } else if (location.href.match('/map.php')) {
        document.querySelector('header nav li:nth-of-type(11)').classList.add('active');
    } else if (location.href.match('outline.php')) {
        document.querySelector('header nav li:nth-of-type(12)').classList.add('active');
    } else {
        document.querySelector('header nav li:nth-of-type(1)').classList.add('active');
    }
}
;


window.addEventListener('load', function () {
    document.querySelector('body').classList.add('show');
});


const header = document.querySelector('header');
window.addEventListener('scroll', function () {
    var sH = window.scrollY;
    if (sH >= 100) {
        header.classList.add('hScroll');
    } else {
        header.classList.remove('hScroll');
    }
});

const gBtn = document.querySelector('.g_menu_btn');
const gNav = document.querySelector('.g_nav');
gBtn.addEventListener('click', function () {
    this.classList.toggle('active');
    gNav.classList.toggle('active');
});

const pageChTrigger = document.querySelectorAll('a:not([href^="#"]):not([target]):not([href^="tel:"])');
for (let i = 0; i < pageChTrigger.length; i++) {
    pageChTrigger[i].addEventListener('click', (event) => {
        event.preventDefault();
        const url = pageChTrigger[i].getAttribute('href');
        console.log(url);
        if (url !== '') {
            document.querySelector('body').classList.remove('show');
            setTimeout(() => {
                window.location = url;
            }, "800")
        }
        return false;
    });
}
;
window.addEventListener("pageshow", function (event) {
    if (event.persisted) {
        document.querySelector('body').classList.add('show');
    }
});


const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
for (let i = 0; i < smoothScrollTrigger.length; i++) {
    smoothScrollTrigger[i].addEventListener('click', (event) => {
        event.preventDefault();
        let href = smoothScrollTrigger[i].getAttribute('href');
        let targetElement = document.getElementById(href.replace('#', ''));
        const rect = targetElement.getBoundingClientRect().top;
        const offset = window.scrollY;
        const gap = 0;
        const target = rect + offset - gap;
        window.scrollTo({
            top: target,
            behavior: 'smooth',
        });
    });
}

$(function(){
  var setElm = $('.text_anime1'),
  className = 'sliceTxt';

  setElm.children().addBack().contents().each(function(){
    var elmThis = $(this);
    if (this.nodeType == 3) {
      var $this = $(this);
      $this.replaceWith($this.text().replace(/(\S)/g, '<span>$&</span>'));
    }
    setElm.each(function(){
      $(this).find('span').each(function(i){
        i = i+1;
        $(this).addClass(className+i);
      });
    });
  });
});

// var setAnimation = lottie.loadAnimation({
//   container: document.getElementById('loading'),
//   renderer: 'svg',
//   loop: false,
//   autoplay: true,
//   path: 'assets/img/common/loading_mv.json'
// });
//
//
// // // アニメーション終了時に実行
// setAnimation.addEventListener("complete", function () {
//   const loadingElement = document.querySelector(".loading");
//   loadingElement.classList.add("isLoaded");{
//     $('header').addClass('display_b');
//   }
// });


window.onload = function() {
  var setAnimation = lottie.loadAnimation({
    container: document.getElementById('loading'),
    renderer: 'svg',
    loop: false,
    autoplay: true,
    path: 'assets/img/common/loading_mv.json'
  });

  // // アニメーション終了時に実行
  setAnimation.addEventListener("complete", function () {
    const loadingElement = document.querySelector(".loading");
    loadingElement.classList.add("isLoaded");{
      $('header').addClass('display_b');
    }
  });

}




let commn_space_tevent = 'ontouchstart' in window ? 'touchstart' : 'click';
let commn_space_spppfig = document.querySelectorAll(".spppfig .scroll-hint-icon-wrap");

commn_space_spppfig.forEach((commn_space_spppfig1) => {

    commn_space_spppfig1.addEventListener(commn_space_tevent, () => {

        commn_space_spppfig1.classList.remove('is-active');

    });
});

// $(window).on('load', function(){
// 	setTimeout(function () {
// 		$('.lead_box p').addClass('show');
// 	}, 1000);
// });
